import gql from 'graphql-tag'

const query = gql`
    query paymentIntentHistories(
      $id: ID!,
      $orderBy: String = "createdAt",
      $order: String = "asc"
    ) {
      paymentIntentHistories(
        paymentIntentId: $id,
        orderBy: $orderBy,
        order: $order
      ) {
        code
        normalizedReturnCode
        description
        createdAt
        status
        statusLabel
      }
}`

/**
 *
 * @param {*} paymentIntentId
 * @param {*} orderBy
 * @param {*} order
 */
async function getTransactionHistory ( params = {} ) {
  const { $api }= Vue.prototype
  const result = await $api.query({
      query,
      variables: params
      // fetchPolicy: 'no-cache' // test
  }).then( res => res )
  .catch( e => e )
  return result
}

export { getTransactionHistory }
