import gql from 'graphql-tag'

const mutation = gql`
  mutation createReportPaymentIntents(
    $paymentMethod: String
    $status: String
    $createdAtBeginDate: ISO8601Date
    $createdAtEndDate: ISO8601Date
  ) {
    createReportPaymentIntents(
      paymentMethod: $paymentMethod
      status: $status
      createdAtBeginDate: $createdAtBeginDate
      createdAtEndDate: $createdAtEndDate
    ) {
      id
      success
      errors {
        fieldName message
      }
    }
}`

/**
 * paymentMethod
 * status
 * createdAtBeginDate
 * createdAtEndDate
 * @param {*} paymentMethod
 * @param {*} status
 * @param {*} createdAtBeginDate
 * @param {*} createdAtEndDate
 */
async function createReportPaymentIntent ( params = {} ) {
    const { $api } = Vue.prototype

    let beginDate = ''
    let endDate = ''

    if (params.createdAtBeginDate && params.createdAtEndDate) {

      let { createdAtBeginDate, createdAtEndDate } = params

      createdAtBeginDate = createdAtBeginDate.split("/")
      createdAtEndDate = createdAtEndDate.split("/")
      beginDate = new Date(createdAtBeginDate[2], createdAtBeginDate[1] - 1, createdAtBeginDate[0]).toISOString().split("T")[0]
      endDate =  new Date(createdAtEndDate[2], createdAtEndDate[1] - 1, createdAtEndDate[0]).toISOString().split("T")[0]

      params.createdAtBeginDate = beginDate
      params.createdAtEndDate = endDate
    }

    const result = await $api.mutate({
      mutation,
      variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { createReportPaymentIntent }
