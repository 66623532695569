import gql from 'graphql-tag'

const mutation = gql`
  mutation refundPaymentIntent(
    $paymentIntentId: ID!
  ) {
    refundPaymentIntent(
      paymentIntentId: $paymentIntentId
    ) {
      success errors
    }
}`

/**
 * PaymentIntentID
 * @param {*} paymentIntentId
 */
async function refundPaymentIntent ( paymentIntentId ) {
    const { $api } = Vue.prototype
    const result = await $api.mutate({
      mutation,
      variables: { paymentIntentId }
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { refundPaymentIntent }
