import { mapActions, mapState } from 'vuex'
import { getTransactionDetail, getTransactionHistory } from '@/queries/transactions/transaction.queries'
import { refundPaymentIntent } from '@/queries/transactions/transactions.mutations'
import { currencyFormat } from '@/helpers'

export default {
  name: 'TTransactionsSinglePage',

  components: {
    TGroupCards: () => import('@/components/group-cards'),
    TGroupHistoryItem: () => import('@/components/group-cards/history-item.vue'),
    TGroupHistoryTransaction: () => import('@/components/group-cards/history-transaction.vue'),
    TGroupDonorItem: () => import('@/components/group-cards/donor-item.vue'),
    TStatusColorBar: () => import('@/components/status/color-bar.vue')
  },

  data () {
    return {
      headerTitle: 'Transação',
      transationId: null,
      modals: {
        refundModal: '',
        modalSuccess: '',
        modalError: '',
        showMessage: null
      },
      currency: 'BRL',
      popup: `<div class='content'><p>Cada tentativa de doação na sua ficha é uma Transação diferente no Trackmob Collect. Em doações mensais, cada mês gera uma nova transação, pois é uma nova quantia sendo transferida para sua organização. Dessa maneira, você consegue visualizar em detalhes o passo a passo do processamento de cada valor.</p></div>`
    }
  },

  computed: {
    ...mapState({
      transactionData: ({ transactions }) => transactions.transactionData,
      isLoading: ({ transactions }) => transactions.showDetailForm,
      customer: ({ transactions }) => transactions.transactionData.customer ? transactions.transactionData.customer : {},
      donorId: ({ transactions }) => transactions.transactionData.customer ? transactions.transactionData.customer.id : '',
      subscriptionId: ({ transactions }) => transactions.transactionData.subscriptionId,
    }),

    formatedValue () {
      return `Doação de ${this.currency} ${currencyFormat(this.transactionData.amount)}`
    },

    getMonthAndYear () {
      const { dueDate } = this.transactionData
      const month = dueDate.split("-")[1]
      const year = dueDate.split("-")[0]
      return `${this.$t(`months.${Number(month)}`)} ${year}`
    },

    paymentIntentId: ({ transactionData }) => transactionData.id,

    canBeRefunded: ({ transactionData }) => transactionData.refundable && transactionData.totalAmount > 0,

    amount: ({ transactionData }) => {
      if (transactionData.status === 'paid' ||
        transactionData.status === 'over_paid' ||
        transactionData.status === 'under_paid') {
          return transactionData.paidAmount
      } else if (transactionData.status === 'refunded') {
        return transactionData.refundedAmount
      } else {
        return transactionData.amount
      }
    }
  },

  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('transactions', [ 'setTransactionData', 'setTransactionType', 'toggleShowDetailForm', 'setTransactionHistory' ]),

    refound () {
      this.modals.refundModal.modal('show')
    },

    confirmRefund () {
      refundPaymentIntent(this.paymentIntentId).then(res => {
        const { success } = res.data.refundPaymentIntent
        if (success) {
          this.modals.modalSuccess.modal('show')
        } else {
          this.modals.modalError.modal('show')
        }
      }).catch(e => {
        Vue.$log.error('error', e)
        this.modals.modalError.modal('show')
      })
    },

    bindjQueryPlugins () {
      $('.tui.popup').popup({
        transition: 'fade'
      })

      this.modals.modalSuccess = $(this.$refs.modalSuccess).modal({
        onHidden: function () {
          location.reload()
        }
      })

      this.modals.modalError = $(this.$refs.modalError).modal()

      this.modals.refundModal = $(this.$refs.modal).modal({
        onDeny    : function () {},
        onApprove : function () { return false }
      })
    }
  },

  mounted () {

    const { id, type } = this.$route.params
    this.setTransactionType(type)
    this.headerTitle = `${this.headerTitle} ${ id ? `<small class="identification">#${id}</small>` : ''} `
    this.transationId = id

    this.toggleShowDetailForm(true)
    const transactionDetail = getTransactionDetail(id)
    const transactionHistory = getTransactionHistory({ id })

    Promise.all([transactionDetail, transactionHistory]).then(res => {
      this.setTransactionData(res[0].data.paymentIntent)
      this.setTransactionHistory(res[1].data.paymentIntentHistories)
      this.toggleShowDetailForm()
    }).catch(e => {
      Vue.$log.error('error', e)
      this.toggleShowDetailForm()
    })

    this.bindjQueryPlugins()
  },

  beforeDestroy () {
    this.modals.refundModal.remove()
    this.modals.modalSuccess.remove()
    this.modals.modalError.remove()
  }
}
