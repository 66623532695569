import gql from 'graphql-tag'

const query = gql`
    query paymentIntents(
      $id: ID!
    ) {
      paymentIntent(
        id: $id
      ) {
        id
        amount
        dueDate
        paidAmount
        refundedAmount
        returnCode
        paymentMethod
        paymentMethodLabel
        processStatus
        processStatusLabel
        status
        statusLabel
        totalAmount
        createdAt
        updatedAt
        refundable
        subscriptionId
        customer {
            id
            birthDate
            fullName
            firstName
            lastName
            documentType
            identification
        }
        paymentCreditCardInfo {
          authCode
          code
          description
          externalTid
          message
          nsu
          softDescriptor
        }
        card {
          firstDigits
          lastDigits
          expiration
          cardBrandName
          holderName
        }
        bankAccount {
          accountDigit
          accountNumber
          agencyDigit
          agencyNumber
          bankCode
          bankName
        }
        paymentBankSlipInfo {
          barCode
          digitableLine
          documentNumber
          externalTid
          ourNumber
          url
        }
        address {
          street
          number
          neighborhood
          complement
          zipcode
          city
          state
        }
      }
}`

/**
 *
 * @param {*} id
 */
async function getTransactionDetail ( id ) {
  const { $api }= Vue.prototype
  const result = await $api.query({
      query,
      variables: { id }
      // fetchPolicy: 'no-cache' // test
  }).then( res => res )
  .catch( e => e )
  return result
}

export { getTransactionDetail }
