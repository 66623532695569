import gql from 'graphql-tag'

const query = gql`
    query paymentIntents(
      $page: Int = 1,
      $limit: Int = 120,
      $outputProcessRequestId: ID,
      $competenceId: ID,
      $paymentMethod: String,
      $createdAtBeginDate: ISO8601Date,
      $createdAtEndDate: ISO8601Date,
      $status: String,
      $orderBy: String = "updatedAt",
      $order: String = "desc") {
    paymentIntents(
      page: $page,
      limit: $limit,
      outputProcessRequestId: $outputProcessRequestId,
      competenceId: $competenceId,
      paymentMethod: $paymentMethod,
      createdAtBeginDate: $createdAtBeginDate,
      createdAtEndDate: $createdAtEndDate,
      status: $status,
      orderBy: $orderBy,
      order: $order
    ) {
        collection {
          id
          status
          statusLabel
          state
          stateLabel
          dueDate
          paymentMethod
          paymentMethodLabel
          amount
          totalAmount
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            companyName
          }
        }
        metadata {
          currentPage
          limitValue
          totalCount
          totalPages
        }
    }
}`

/**
 *
 * @param {*} page
 * @param {*} limit
 * @param {*} outputProcessRequestId
 * @param {*} competenceId
 * @param {*} paymentMethod
 * @param {*} createdAtBeginDate
 * @param {*} createdAtEndDate
 * @param {*} orderBy
 * @param {*} order
 */
async function getTransactions ( params = {} ) {
    const { $api }= Vue.prototype
    let beginDate = ''
    let endDate = ''

    if (params.createdAtBeginDate && params.createdAtEndDate) {

      let { createdAtBeginDate, createdAtEndDate } = params

      createdAtBeginDate = createdAtBeginDate.split("/")
      createdAtEndDate = createdAtEndDate.split("/")
      beginDate = new Date(createdAtBeginDate[2], createdAtBeginDate[1] - 1, createdAtBeginDate[0]).toISOString().split("T")[0]
      endDate =  new Date(createdAtEndDate[2], createdAtEndDate[1] - 1, createdAtEndDate[0]).toISOString().split("T")[0]

      params.createdAtBeginDate = beginDate
      params.createdAtEndDate = endDate
    }

    const result = await $api.query({
        query,
        fetchPolicy: 'no-cache',
        variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getTransactions }
